import gsap from 'gsap';
import $ from '../core/Dom';

const loadMapbox = require('bundle-loader?lazy&name=[name]!mapbox-gl');

export default (el, props) => {
    const $el = $(el);

    const {
        cases,
        apiKey
    } = props;

    let mapboxgl = null;
    let map = null;
    let markers = [];

    const init = () => {
        loadMapbox(loadedmapboxgl => {
            mapboxgl = loadedmapboxgl;

            mapboxgl.accessToken = apiKey;

            map = new mapboxgl.Map({
                container: el,
                scrollZoom: false,
                doubleClickZoom: true,
                style: 'mapbox://styles/nyekjeller/cm3eij1vf005i01qu93gm2tlk',
                attributionControl: false
            })
                .addControl(new mapboxgl.FullscreenControl())
                .addControl(new mapboxgl.ScaleControl())
                .addControl(new mapboxgl.NavigationControl(), 'top-right');

            markers = [];

            const geojsonPoints = {
                type: 'FeatureCollection',
                features: cases.filter(marker => marker.location !== null)
                    .map(marker => ({
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [marker.location.lng, marker.location.lat]
                        },
                        properties: {
                            title: marker.title,
                            url: marker.url
                        }
                    }))
            };

            map.on('style.load', () => {
                map.addSource('markers', {
                    type: 'geojson',
                    data: geojsonPoints,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50
                });

                // Add a layer just for clustering logic (invisible)
                map.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'markers',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-opacity': 0
                    }
                });

                map.addLayer({
                    id: 'unclustered-point',
                    type: 'circle',
                    source: 'markers',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-opacity': 0
                    }
                });

                map.on('sourcedata', (e) => {
                    if (e.sourceId === 'markers' && e.isSourceLoaded) {
                        updateMarkers();
                    }
                });

                map.on('moveend', updateMarkers);
                map.on('zoomend', updateMarkers);

                // Fit bounds
                const bounds = new mapboxgl.LngLatBounds();
                geojsonPoints.features.forEach(feature => {
                    bounds.extend(feature.geometry.coordinates);
                });

                map.fitBounds(bounds, {
                    animate: false,
                    padding: 75
                });

                map.setCenter(bounds.getCenter());
            });

            map.on('data', (e) => {
                if (e.sourceId === 'markers' && e.isSourceLoaded) {
                    updateMarkers();
                }
            });
        });
    };

    const destroy = () => {
        if (map) {
            markers.forEach(marker => marker.remove());
            markers = [];
            map.remove();
        }
    };

    const createClusterMarker = (count, clusterId) => {
        return $(`
            <div class="size-[64px] flex items-center justify-center bg-orange-signal rounded-[32px] transition-all duration-50 cursor-pointer" data-cluster-id="${clusterId}">
                <span class="text-black h4">
                    ${count}
                </span>
            </div>
        `)
            .get(0);
    };

    const createNormalMarker = (properties) => {
        return $(`
            <div class="hover:z-2 [&.active]:z-1">
                <a href="${properties.url}" 
                   class="bg-orange-signal text-black rounded-[4px] small px-10 py-4 select-none transition-all duration-50">
                    ${properties.title}
                </a>
            </div>
        `)
            .get(0);
    };

    const createMarker = (feature, map) => {
        const element = feature.properties.cluster
            ? createClusterMarker(feature.properties.point_count, feature.properties.cluster_id)
            : createNormalMarker(feature.properties);

        if (feature.properties.cluster) {
            element.addEventListener('click', () => {
                const clusterId = feature.properties.cluster_id;
                map.getSource('markers')
                    .getClusterExpansionZoom(
                        clusterId,
                        (err, zoom) => {
                            if (err) return;

                            map.easeTo({
                                center: feature.geometry.coordinates,
                                zoom: zoom + 0.1
                            });
                        }
                    );
            });
        }

        return new mapboxgl.Marker({ element })
            .setLngLat(feature.geometry.coordinates);
    };

    const updateMarkers = () => {
        markers.forEach(marker => marker.remove());
        markers = [];

        const features = map.querySourceFeatures('markers');
        const uniqueCoords = new Set();

        features.forEach(feature => {
            const coords = `${feature.geometry.coordinates[0]},${feature.geometry.coordinates[1]}`;

            if (!uniqueCoords.has(coords)) {
                uniqueCoords.add(coords);
                const marker = createMarker(feature, map).addTo(map);
                markers.push(marker);
            }
        });
    };

    return {
        init,
        destroy
    };
};
