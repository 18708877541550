import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const BP_ALWAYS_HEIGHT = 750;

    const $el = $(el);
    const $body = $('body');
    const $menu = $el.find('#menu');
    const $menuContent = $menu.find('[data-panel-content]');
    const $menuBtn = $el.find('[data-menu-toggle-btn]');
    const $portfolio = $el.find('#portfolio-menu');
    const $portfolioWrapper = $portfolio.find('[data-panel-wrapper]');
    const $portfolioContent = $portfolio.find('[data-panel-content]');
    const $portfolioBtn = $el.find('[data-portfolio-toggle-btn]');

    let isOpenMenu = false;
    let isOpenPortfolioMenu = false;

    const $bar = $el.find('[data-page-header-bar]');
    const $barBg = $el.find('[data-page-header-bar-bg]');

    let isHidden = false;
    let isLocked = false;
    let hiddenThreshold = 30;
    let wasTransparent = true;
    let currentScrollTop = Viewport.scrollTop;
    let breakpoint = Viewport.breakpoint.size;

    const init = () => {
        $menuBtn
            .attr({
                tabIndex: '0',
                role: 'button',
                'aria-expanded': 'false'
            })
            .on('keydown', e => {
                const key = e.key || e.keyCode || e.which || null;
                if (['Enter', 13].indexOf(key) > -1) {
                    e.preventDefault();
                    toggleMenu();
                }
            })
            .on('click', e => {
                e.preventDefault();
                toggleMenu();
            })
            .get(0)
            .removeAttribute('href');

        if ($portfolioBtn.length > 0) {
            $portfolioBtn
                .attr({
                    tabIndex: '0',
                    role: 'button',
                    'aria-expanded': 'false'
                })
                .on('keydown', e => {
                    const key = e.key || e.keyCode || e.which || null;
                    if (['Enter', 13].indexOf(key) > -1) {
                        e.preventDefault();
                        togglePortfolioMenu();
                    }
                })
                .on('click', e => {
                    e.preventDefault();
                    togglePortfolioMenu();
                })
                .get(0)
                .removeAttribute('href');
        }

        $menu.css({ display: 'block', height: 0, overflow: 'hidden' });
        $menuContent.css({ opacity: 0 });
        $portfolio.css({ display: 'none' });
        $portfolioWrapper.css({ height: 0, overflow: 'hidden' });
        $portfolioContent.css({ opacity: 0 });
        
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
        
        $body.on('keyup', onBodyKeyUp);
        $el.on('focusin', 'a,button', onInnerFocus);

        $bar.removeClass('js:opacity-0');
        $barBg.removeClass('js:opacity-0');

        // Account for the menu being opened already before the JS had the chance to boot
        requestAnimationFrame(() => {
            if (window.scrollY > hiddenThreshold) {
                hide(true);
            }
        });

    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);

        $el.off('focusin');
        
    };

    const onBodyKeyUp = e => {
        if (!isOpenMenu && !isOpenPortfolioMenu) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            if (isOpenMenu) {
                toggleMenu();
            }
            if (isOpenPortfolioMenu) {
                togglePortfolioMenu();
            }
        }
    };
    
    const onBreakpoint = () => {
        breakpoint = Viewport.breakpoint.size;
    };

    const onResize = () => {
        hiddenThreshold = getThreshold();

        if (!isHidden) {
            return;
        }

        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop <= hiddenThreshold) {
                show();
            }
        });
    };

    const onInnerFocus = e => {
        show();
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }

        if (scrollTop < hiddenThreshold) {
            show();
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
        }

        if (wasTransparent) {
            if (scrollTop < hiddenThreshold && !$el.hasClass('is-transparent')) {
                $el.addClass('is-transparent');
            } else if (scrollTop >= hiddenThreshold && $el.hasClass('is-transparent')) {
                $el.removeClass('is-transparent');
            }
        }

        currentScrollTop = scrollTop;
    };

    const toggleMenu = () => {
        if (isOpenMenu) {
            gsap.to($menuContent.get(0), { duration: 0.1, opacity: 0 });
            gsap.to($menu.get(0), { duration: 0.6, height: 0, ease: 'quint.inOut', onComplete: () => {
                $menu.css({ display: 'none' });
            } });
        } else {
            $menu.css({ display: 'block' });
            gsap.to($menu.get(0), { duration: 0.6, height: '100%', ease: 'quint.inOut' });
            gsap.to($menuContent.get(0), { duration: 0.5, delay: 0.4, opacity: 1 });
        }

        isOpenMenu = !isOpenMenu;
        $menuBtn.attr('aria-expanded', isOpenMenu ? 'true' : 'false');
    };

    const togglePortfolioMenu = () => {
        if (isOpenPortfolioMenu) {
            gsap.to($portfolioContent.get(0), { duration: 0.1, opacity: 0 });
            gsap.to($portfolioWrapper.get(0), { duration: 0.6, height: 0, ease: 'quint.inOut', onComplete: () => {
                $portfolio.css({ display: 'none' });
            } });
        } else {
            $portfolio.css({ display: 'block' });
            gsap.to($portfolioWrapper.get(0), { duration: 0.6, height: 'auto', ease: 'quint.inOut' });
            gsap.to($portfolioContent.get(0), { duration: 0.5, delay: 0.4, opacity: 1 });
        }

        isOpenPortfolioMenu = !isOpenPortfolioMenu;
        $portfolioBtn.attr('aria-expanded', isOpenPortfolioMenu ? 'true' : 'false');
    };

    /**
     * Scroll behaviour
     */
    const hide = (force) => {
        if (isHidden) {
            return;
        }
        isHidden = true;

        if (force) {
            $el.addClass('is-forced');

            setTimeout(() => {
                $el.removeClass('is-forced');
            }, 600);
        }

        $el.addClass('is-hidden');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        $el.removeClass('is-hidden');
    };

    const lock = () => {
        if (isLocked) {
            return;
        }
        isLocked = true;
        $el.addClass('is-locked');
    };

    const unlock = () => {
        if (!isLocked) {
            return;
        }
        isLocked = false;
        $el.removeClass('is-locked');
    };

    const getThreshold = () => {
        if (breakpoint < 1240) {
            return 64;
        } else if (breakpoint < 1420) {
            return 80;
        }

        return 110;
    };

    return {
        init,
        destroy
    };

};
