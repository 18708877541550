import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $links = $el.find('a');
    let nextIndex = 0;

    const init = () => {
        $links.on('mouseenter', onMouseEnter);
        $links.on('mouseleave', onMouseLeave);
    };

    const destroy = () => {
        $el.off('mouseenter');
        $el.off('mouseleave');
    };
    
    const onMouseEnter = e => {
        const triggerCategory = $(e.triggerTarget).data('category-link');
        const $imgs = $el.find('[data-category-image="' + triggerCategory + '"]');
        
        if (nextIndex >= $imgs.length) {
            nextIndex = 0;
        }
        
        const $img = $imgs.eq(nextIndex);
        gsap.killTweensOf($img.get(0));
        gsap.to($img.get(0), { opacity: 1, duration: 0.1, ease: 'sine.out' });
        
        nextIndex += 1;
    };
    
    const onMouseLeave = e => {
        const triggerCategory = $(e.triggerTarget).data('category-link');
        const $img = $el.find('[data-category-image="' + triggerCategory + '"]');
        gsap.to($img.nodes, { opacity: 0, duration: 0.05, ease: 'sine.in' });
    };
    
    return {
        init,
        destroy
    };

};
