import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ThemeSwitcher from './lib/ThemeSwitcher';
import ModalSlideshow from './lib/ModalSlideshow';
import ModalInterestForm from './lib/ModalInterestForm';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ThemeSwitcher.init();
    ModalSlideshow.init();
    ModalInterestForm.init();
    lazySizes.init();

    window.addEventListener('popstate', e => {
        const { state } = e;

        if (state && state.reloadBack) {
            window.location.reload();
        }
    });

    $('[data-modal-slideshow-btn]')
        .on('click', onModalSlideshowBtnClick);

    $('a[href*=\'/se/intresseanmalan/\']')
        .on('click', onInterestFormModalOpen);
};

const onModalSlideshowBtnClick = e => {
    e.preventDefault();
    const $trigger = $(e.triggerTarget);
    ModalSlideshow.open($trigger.data('modal-slideshow-btn'));
};

const onInterestFormModalOpen = e => {
    e.preventDefault();
    const $trigger = $(e.triggerTarget);
    ModalInterestForm.open($trigger.attr('href'));
};

require('doc-ready')(init);
