import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const $body = $('body');
const themeElements = $('[data-theme-switch]');
let currentClass = '';
let pageClass = '';
let def = [];

const onResize = () => {
    if (themeElements.length) {
        themeElements.each(node => {
            const $el = $(node);
            
            def.push({
                node: node,
                top: $el.offset().top,
                height: $el.height(),
                themeClass: $el.data('theme-switch')
            });
        });
        
        onScroll();
    }
};

const onScroll = () => {
    const scrollTop = Viewport.scrollTop;
    const viewportHeight = Viewport.height;
    
    let useClass = pageClass;
    
    def.forEach(e => {
        if (e.top < scrollTop + (viewportHeight*0.5)) {
            useClass = e.themeClass;
        }
    });
    
    if (useClass !== currentClass) {
        $body.removeClass(currentClass).addClass(useClass);
        currentClass = useClass;
    }
};

const init = () => {
    pageClass = $body.data('page-theme');
    currentClass = pageClass;

    Viewport.on('resize', onResize);
    Viewport.on('scroll', onScroll);

    onResize();
};

export default {
    init
};
