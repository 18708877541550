import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

export default (el, props) => {
    const $el = $(el);
    const $clearBtn = $el.find('[data-clear-field-button]') 
    const $searchInput = $el.find('input[type="text"],input[type="search"]') 

    const init = () => {
        $clearBtn.on('click', onClearClick);
        $searchInput.on('input', onInputChange)
        
        onInputChange();
    };

    const destroy = () => {
        
    };
    
    const onClearClick = e => {
        e.preventDefault();
        $searchInput.val('');
        $searchInput.focus();
        onInputChange();
    };
    
    const onInputChange = () => {
        if ($searchInput.val() === '') {
            $clearBtn.css({ display: 'none' });
        } else {
            $clearBtn.css({ display: 'block' });
        }
    };

    return {
        init,
        destroy
    };
};
