import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const BP_ALWAYS_HEIGHT = 750;
    
    const $el = $(el);
    const $button = $el.find('button');
    const $target = $('#'+$button.attr('aria-controls'));
    
    let isEnabled = false;
    let initialHeight = $el.height();

    const init = () => {
        $button.on('click', onClick);
        
        if (Viewport.breakpoint.size < BP_ALWAYS_HEIGHT) {
            gsap.set($target.get(0), { height: initialHeight, opacity: 0 });
        } else {
            gsap.set($target.get(0), { height: 'auto', opacity: 0 });
        }
    };

    const destroy = () => {
        collapse();
        $button.on('click', onClick);
    };
    
    const expand = () => {
        if (isEnabled) {
            return;
        }
        
        $button.attr('aria-expanded', 'true');
        
        if (Viewport.breakpoint.size < BP_ALWAYS_HEIGHT) {
            gsap.to($target.get(0), { duration: 0.4, ease: 'sine.out', height: 'auto' });
            gsap.to($target.get(0), { duration: 0.2, delay: 0.3, opacity: 1 });
        } else {
            gsap.set($target.get(0), { duration: 0.3, opacity: 1 });
        }
        
        isEnabled = true;
    };

    const collapse = () => {
        if (!isEnabled) {
            return;
        }
        $button.attr('aria-expanded', 'false');

        if (Viewport.breakpoint.size < BP_ALWAYS_HEIGHT) {
            gsap.to($target.get(0), { duration: 0.1, opacity: 0 });
            gsap.to($target.get(0), { duration: 0.4, delay: 0.1, ease: 'sine.out', height: initialHeight });
        } else {
            gsap.set($target.get(0), { duration: 0.3, opacity: 0 });
        }
        
        isEnabled = false;
    };

    const onClick = () => {
        if (isEnabled) {
            collapse();
        } else {
            expand();
        }
    };
    
    return {
        init,
        destroy
    };

};
