import gsap from 'gsap';
import axios from 'axios';

import $ from '../core/Dom';
import Config from '../core/Config';
import Components from '../core/Components';

const $body = $('body');
const $modal = $body.find('[data-modal-slideshow]');
const $heading = $modal.find('[data-modal-heading]');
const $inner = $modal.find('[data-modal-inner]');
const $content = $modal.find('[data-modal-contents-wrapper]');
const $closeBtn = $modal.find('[data-close-btn]');

let isOpen = false;
let loadUrl = Config.get('urls').loadModalSlideshow || '';

const open = uid => {
    $modal.css('display', null);

    gsap.set([$modal.get(0), $inner.get(0)], { opacity: 0 });
    gsap.to($modal.get(0), {
        opacity: 1,
        duration: 0.4,
        ease: 'sine.out'
    });
    
    loadContents(uid)

    isOpen = true;
};

const loadContents = uid => {
    axios({
        url: loadUrl,
        params: { uid },
        method: 'get',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'text/html' },
    })
        .then(res => {
            const { status, data } = res || {};

            if (status === 200 && data) {
                addContent(data);
            } else {
                // Do something else
            }
        })
        .catch(error => {
            console.error(error);
        });
};

const addContent = text => {
    const $html = $(text)
    $html.find('noscript').remove();
    
    $content.empty();
    $content.append($html);
    
    $heading.text($html.data('heading'));
    
    Components.init($content);
    gsap.to($inner.get(0), { opacity: 1, duration: 0.6, delay: 0.4 });
};


const close = () => {
    gsap.to($modal.get(0), {
        opacity: 0,
        duration: 0.2,
        ease: 'sine.in',
        onComplete: () => {
            $modal.css('display', 'none');
            $modal.css('opacity', '');
            
            //$embedWrapper.empty();
        }
    });

    isOpen = true;
};

const onBodyKeyUp = e => {
    if (!isOpen) {
        return;
    }
    const key = e.key || e.keyCode || e.which || null;
    if (['Escape', 27].indexOf(key) > -1) {
        close();
    }
};

const init = () => {
    $body.on('keyup', onBodyKeyUp);
    $closeBtn.on('click', close);

    $modal.on('click', e => {
        if (e.target === $modal.get(0)) {
            close();
        }
    });
};

export default {
    init,
    open,
    close
};
