import gsap from 'gsap';
import axios from 'axios';

import $ from '../core/Dom';
import Components from '../core/Components';

export default (el, props) => {
    const $el = $(el);

    const $filterTarget = $el.find('[data-filter-target]');
    const $filterItems = $el.find('[data-filter-item]');

    let hasUpdatedHistory = false;

    const init = () => {
        if ($filterTarget.length > 0) {
            $el.on('click', '[data-filter-item]', onFilterItemClick);
            
            if (window.history) {
                window.history.replaceState({ reloadBack: true }, document.title);
            }
        }
    };

    const destroy = () => {
        $el.off('click');
    };

    const onFilterItemClick = e => {
        e.preventDefault();

        const $triggerTarget = $(e.triggerTarget);
        const loadUrl = $triggerTarget.attr('href');
        
        $filterItems.removeClass('selected');
        $triggerTarget.addClass('selected');

        gsap.to($filterTarget.get(0), {
            opacity: 0.2,
            duration: 0.2
        });

        axios({
            url: loadUrl,
            method: 'get',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'text/html'
            }
        })
            .then(res => {
                const {
                    status,
                    data
                } = res || {};

                if (status === 200 && data) {
                    addContent(data);

                    if (window.history) {
                        if (hasUpdatedHistory) {
                            window.history.replaceState({ reloadBack: true }, document.title, loadUrl);
                        } else {
                            window.history.pushState({ reloadBack: true }, document.title, loadUrl);
                        }

                        hasUpdatedHistory = true;
                    }
                    

                } else {
                    // Do something else
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const addContent = text => {
        const $html = $(text);
        const $newContent = $html.find('[data-filter-target]');
        $newContent.find('noscript').remove();

        $filterTarget.empty();
        $filterTarget.append($newContent.get(0).children);

        Components.init($filterTarget);

        gsap.set($filterTarget.get(0), { opacity: 0 });

        gsap.to($filterTarget.get(0), {
            opacity: 1,
            duration: 0.6,
            delay: 0.4
        });
    };

    return {
        init,
        destroy
    };

};
