import gsap from 'gsap';

import $ from '../core/Dom';

const loadMapbox = require('bundle-loader?lazy&name=[name]!mapbox-gl');

export default (el, props) => {
    const $el = $(el);

    const { location, apiKey } = props;

    const markerIcons = {
        default: '<div class="map-marker"></div>'
    };

    const init = () => {
        loadMapbox(mapboxgl => {
            mapboxgl.accessToken = apiKey;

            const map = new mapboxgl.Map({
                container: el,
                scrollZoom: false,
                style: 'mapbox://styles/nyekjeller/cm3eij1vf005i01qu93gm2tlk',
                center: [location.lng, location.lat],
                zoom: 14,
                attributionControl: false
            }).addControl(new mapboxgl.AttributionControl({
                compact: true
            }));

            const nav = new mapboxgl.NavigationControl();
            map.addControl(nav, 'top-right');

            const marker = new mapboxgl.Marker({
                element: $(markerIcons.default).get(0)
            }).setLngLat([location.lng, location.lat])
                //.setPopup(new mapboxgl.Popup().setHTML('<div class="text-blue-dark">' + marker.popupContent + '</div>'))
                .addTo(map);
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
