import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $html = $('html');
    const $links = $el.find('a');

    const init = () => {
        $links.on('mouseenter', onMouseEnter);
        $links.on('mouseleave', onMouseLeave);
    };

    const destroy = () => {
        $el.off('mouseenter');
        $el.off('mouseleave');
    };

    const onMouseEnter = e => {
        if ($html.hasClass('using-mouse')) {
            const $img = $(e.triggerTarget)
                .find('[data-hover-image]');
            
            gsap.killTweensOf($img.get(0));
            gsap.to($img.get(0), {
                width: 'auto',
                duration: 0.5,
                ease: 'expo.out'
            });
        }
    };

    const onMouseLeave = e => {
        if ($html.hasClass('using-mouse')) {
            const $img = $(e.triggerTarget)
                .find('[data-hover-image]');
            
            gsap.to($img.get(0), {
                width: 0,
                duration: 0.4,
                delay: 0.3,
                ease: 'sine.out'
            });
        }
    };

    return {
        init,
        destroy
    };

};
