import gsap from 'gsap';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import { COMPONENT_INIT } from '../lib/events';

export default (button, props) => {

    const {
        autoClose = false,
        focusFirstInput = false
    } = props || {};
    const target = document.getElementById(button.getAttribute('aria-controls'));
    const $target = $(target);

    const isExpanded = () => !target.classList.contains('hidden');

    const expand = () => {
        if (isExpanded()) {
            return;
        }
        document.body.querySelectorAll(`button[aria-controls="${target.id}"]`)
            .forEach(toggle => {
                toggle.setAttribute('aria-expanded', 'true');
            });

        target.classList.remove('hidden');

        gsap.set(target, { height: '' });
        const targetHeight = $target.height();
        gsap.set(target, { height: 0, opacity: 0 });
        gsap.to(target, {
            height: targetHeight,
            opacity: 1,
            duration: 0.4,
            ease: 'quint.out'
        });

        if (focusFirstInput) {
            const firstFocusable = target.querySelector('input:not([type="hidden"]):not([disabled]),button');
            if (firstFocusable) {
                firstFocusable.focus();
            }
        }
    };

    const collapse = () => {
        if (!isExpanded()) {
            return;
        }
        document.body.querySelectorAll(`button[aria-controls="${target.id}"]`)
            .forEach(toggle => {
                toggle.setAttribute('aria-expanded', 'false');
            });

        gsap.to(target, {
            opacity: 0,
            height: 0,
            duration: 0.15,
            ease: 'sine.out',
            onComplete: () => {
                target.classList.add('hidden');
            }
        });

        if (autoClose && (!document.activeElement || document.activeElement === document.body || document.activeElement.offsetParent === null)) {
            button.focus();
        }
    };

    const onClick = () => {
        if (isExpanded()) {
            collapse();
        } else {
            expand();
        }
    };

    const onBodyClickFocus = e => {
        if (!isExpanded()) {
            return;
        }
        if (e.target === button || button.contains(e.target) || e.target === target || target.contains(e.target)) {
            return;
        }
        collapse();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded() || e.key !== 'Escape') {
            return;
        }
        e.stopPropagation();
        collapse();
    };

    const init = () => {
        button.addEventListener('click', onClick);
        if (autoClose) {
            document.body.addEventListener('click', onBodyClickFocus);
            document.body.addEventListener('focusin', onBodyClickFocus);
            document.body.addEventListener('keyup', onBodyKeyUp);
        }

        target.setAttribute('data-disclosure-target', true);
        target.querySelectorAll(`button[aria-controls="${target.id}"]`)
            .forEach(closeBtn => {
                closeBtn.addEventListener('click', collapse);
            });

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        collapse();
        button.removeEventListener('click', onClick);
        if (autoClose) {
            document.body.removeEventListener('click', onBodyClickFocus);
            document.body.removeEventListener('focusin', onBodyClickFocus);
            document.body.removeEventListener('keyup', onBodyKeyUp);
        }

        target.querySelectorAll(`button[aria-controls="${target.id}"]`)
            .forEach(closeBtn => {
                closeBtn.removeEventListener('click', collapse);
            });
    };

    return {
        init,
        destroy
    };

};
