import gsap from 'gsap';
import axios from 'axios';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Components from '../core/Components';

import { COMPONENT_INIT } from '../lib/events';

export default (el, props) => {

    const $el = $(el);
    const $body = $('body');
    const $closeButton = $el.find('[data-filter-close-button]');

    const $filter = $el.find('#premisesFilter');
    const $filterInner = $filter.find('[data-filter-inner]');
    const $resultList = $body.find('#premisesResultList');

    let hasUpdatedHistory = false;
    
    const isExpanded = () => !$filter.hasClass('hidden');

    const init = () => {
        if (window.history) {
            window.history.replaceState({ reloadBack: true }, document.title);
        }

        $el.on('click', '[data-filter-trigger]', onClick);
        $filter.on('click', onBgClick);
        $closeButton.on('click', onClick);
        $body.on('keyup', onBodyKeyUp);

        $filter.attr('data-disclosure-target', true);
        $filter.on('click', '[data-filter-item]', onFilterItemClick);
        
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        collapse();

        $el.off('click');
        $filter.off('click');
        $closeButton.off('click');
        $body.off('keyup');
    };
    
    const onFilterItemClick = e => {
        e.preventDefault();
        const $triggerTarget = $(e.triggerTarget);
        
        const url = $triggerTarget.attr('href');
        const triggerId = $triggerTarget.data('link-id');

        doFilter(url, triggerId);
    };
    
    const doFilter = (url, triggerId) => {
        const listType = triggerId.slice(0, 4);
        const $filterList = $filter.find('[data-filter-list="'+listType+'"]');

        $filterList.find('[data-link-id]').removeClass('selected');
        $filterList.find('[data-link-id="'+triggerId+'"]').addClass('selected');
        
        gsap.to($resultList.get(0), {
            opacity: 0,
            duration: 0.2
        });
        
        axios({
            url: url,
            method: 'get',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'text/html' },
        })
            .then(res => {
                const { status, data } = res || {};

                if (status === 200 && data) {
                    addContent(data, triggerId);
                    
                    if (window.history) {
                        if (hasUpdatedHistory) {
                            window.history.replaceState({ reloadBack: true }, document.title, url);
                        } else {
                            window.history.pushState({ reloadBack: true }, document.title, url);
                        }

                        hasUpdatedHistory = true;
                    }
                    
                } else {
                    // Do something else
                }
            })
            .catch(error => {
                console.error(error);
            });
        
    };
    
    const addContent = (data, triggerId) => {
        const $html = $(data)
        $html.find('noscript').remove();
        
        const $newresult = $html.find('#premisesResultList');
        
        $resultList.empty();
        $resultList.append($newresult);
        
        const $filterLists = $el.find('[data-filter-list]');
        
        $filterLists.each((el) => {
            const $el = $(el);
            const listType = $el.data('filter-list');
            
            const $currentFilter = $filter.find('[data-filter-list="'+listType+'"]');
            const $newFilter = $html.find('[data-filter-list="'+listType+'"]');
            
            $currentFilter.empty().append($newFilter.get(0).children);
        });
        
        const $viewArea = $el.find('[data-view-link-area]');
        const $newViewArea = $html.find('[data-view-link-area]');
        $viewArea.empty().append($newViewArea.get(0).children);
        
        Components.init($newresult);
        gsap.to($resultList.get(0), { opacity: 1, duration: 0.6, delay: 0.4 });
        
    };
    
    const expand = () => {
        if (isExpanded()) {
            return;
        }
        
        $el.find('[data-filter-trigger]').attr('aria-expanded', 'true');
        $filter.removeClass('hidden');

        const inner = $filterInner.get(0);

        gsap.set($filter.get(0), { opacity: 0 });
        gsap.set($closeButton.get(0), { opacity: 0 });
        gsap.set(inner, { x: $filterInner.width() });

        gsap.to($closeButton.get(0), {
            opacity: 1,
            duration: 0.3,
            delay: 0.4,
            ease: 'sine.out'
        });

        gsap.to($filter.get(0), {
            opacity: 1,
            duration: 0.2,
            ease: 'sine.out'
        });

        gsap.to(inner, {
            x: 0,
            duration: 0.6,
            ease: 'quint.inOut'
        });

        $closeButton.get(0)
            .focus();
    };

    const collapse = () => {
        if (!isExpanded()) {
            return;
        }

        $el.find('[data-filter-trigger]').attr('aria-expanded', 'false');

        const inner = $filterInner.get(0);

        gsap.to($closeButton.get(0), {
            opacity: 0,
            duration: 0.1,
            ease: 'sine.out'
        });

        gsap.to(inner, {
            x: $filterInner.width(),
            duration: 0.4,
            ease: 'sine.out'
        });

        gsap.to($filter.get(0), {
            opacity: 0,
            duration: 0.3,
            delay: 0.2,
            ease: 'sine.out',

            onComplete: () => {
                $filter.addClass('hidden');
            }
        });
    };

    const onClick = () => {
        if (isExpanded()) {
            collapse();
        } else {
            expand();
        }
    };

    const onBgClick = e => {
        if (!isExpanded()) {
            return;
        }
        if (e.target === $filterInner.get(0) || $filterInner.get(0)
            .contains(e.target)) {
            return;
        }
        collapse();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded() || e.key !== 'Escape') {
            return;
        }
        e.stopPropagation();
        collapse();
    };
    

    return {
        init,
        destroy
    };

};
